import { lazy } from "react";
import Loadable from "components/Loadable";
import { CatalogMasterTabsEnum } from "components/native-ecommerce/catalog-master/catalog-master-tabs";


const AdminDashboardPage = Loadable(lazy(() => import("pages/admin/admin-dashboard.page")));
const AdminUsersPage = Loadable(lazy(() => import("pages/admin/admin-users.page")));
const AdminApiPage = Loadable(lazy(() => import("pages/admin/admin-api.page")));
const AdminEcommercePage = Loadable(
  lazy(() => import("pages/admin/admin-ecommerce.page"))
);
const AdminCatalogBatchManagerPage = Loadable(
  lazy(() => import("pages/admin/admin-catalog-batch-manager.page"))
);
const AdminCatalogMasterManagerPage = Loadable(
  lazy(() => import("pages/admin/admin-catalog-master-manager.page"))
);
const AdminCatalogMasterManagerAddEditProductPage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-add-edit-product.page"))
);
const AdminCatalogMasterManagerAddEditStrainTypePage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-add-edit-strain-type.page"))
);
const AdminCatalogMasterManagerAddEditCannabinoidPage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-add-edit-cannabinoid.page"))
);
const AdminCatalogMasterManagerAddEditTerpenePage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-add-edit-terpene.page"))
);
const AdminCatalogMasterManagerAddEditEffectPage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-add-edit-effect.page"))
);
const AdminCatalogMasterManagerAddEditSubcategoryPage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-add-edit-subcategory.page"))
);
const AdminCatalogMasterManagerAddEditBrandPage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-add-edit-brand.page"))
);
const AdminCatalogMasterManagerAddEditCategoryPage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-add-edit-category.page"))
);
const AdminCatalogMasterManagerProductUploadsPage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-manager-product-upload.page"))
);
const AdminCatalogMasterManagerProductUploadsNewPage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-manager-product-upload-new.page"))
);
const AdminCatalogMasterManagerProductUploadsEditPage = Loadable(
  lazy(() => import("pages/catalog-master-manager/admin-catalog-master-manager-product-upload-edit.page"))
);

const AdminMccPage = Loadable(lazy(() => import("pages/admin/admin-mcc.page")));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = [
  {
    index: true,
    element: <AdminDashboardPage title="Admin" />,
  },
  {
    path: "users",
    element: <AdminUsersPage title="Admin Users" />,
    handle: {
      crumb: () => 'Users',
    },
  },
  {
    path: "api",
    element: <AdminApiPage title="API Keys" />,
    handle: {
      crumb: () => 'Api',
    },
  },
  {
    path: "api/access-logs",
    element: <AdminApiPage title="Access Logs" />,
    handle: {
      crumb: () => 'Access Logs',
    },
  },
  {
    path: "ecommerce",
    element: <AdminEcommercePage title="Ecommerce Schedule" />,
    handle: {
      crumb: () => 'Ecommerce',
    },
  },
  {
    path: "catalog-batch-manager",
    element: <AdminCatalogBatchManagerPage title="Batch Catalog Manager" />,
    handle: {
      crumb: () => 'Batch Catalog Manager',
    },
  },
  {
    path: "catalog-master-manager",
    element: <AdminCatalogMasterManagerPage title="Master Catalog Manager" />,
    handle: {
      crumb: () => 'Master Catalog Manager',
    },
    children: [
      {
        path: "brands",
        handle: {
          crumb: () => 'Brands',
        },
        children: [
          {
            path: ":brandId",
            element: <AdminCatalogMasterManagerAddEditBrandPage title="Master Catalog Manager - Edit Brand" />,
            handle: {
              crumb: () => { return `Edit Brand` },
            },
          },
          {
            path: "new",
            element: <AdminCatalogMasterManagerAddEditBrandPage title="Master Catalog Manager - Add Brand" />,
            handle: {
              crumb: () => { return `Add Brand` },
            },
          },
        ]
      },
      {
        path: "products",
        handle: {
          crumb: () => 'Products',
        },
        children: [
          {
            path: ":productId",
            element: <AdminCatalogMasterManagerAddEditProductPage title="Master Catalog Manager - Edit Product" />,
            handle: {
              crumb: () => { return `Edit Product` },
            },
          },
          {
            path: "new",
            element: <AdminCatalogMasterManagerAddEditProductPage title="Master Catalog Manager - Add Product" />,
            handle: {
              crumb: () => { return `Add Product` },
            },
          },
          {
            path: "uploads",
            element: <AdminCatalogMasterManagerProductUploadsPage title="Master Catalog Manager - Product Uploads" />,
            handle: {
              crumb: () => { return `Uploads` },
            },
            
            children: [
              {
                path: "new",
                element: <AdminCatalogMasterManagerProductUploadsNewPage title="Master Catalog Manager - Product Uploads - New" />,
                handle: {
                  crumb: () => { return `New Upload` },
                },
              },
              {
                path: ":uploadId",
                element: <AdminCatalogMasterManagerProductUploadsEditPage title="Master Catalog Manager - Product Uploads - Edit" />,
                handle: {
                  crumb: () => { return `Edit Upload` },
                },
              }
            ]
          },
          {
            path: "upload",
            element: <AdminCatalogMasterManagerAddEditProductPage title="Master Catalog Manager - Add Product" />,
            handle: {
              crumb: () => { return `Add Product` },
            },
          },
        ]
      },

      {
        path: "categories",
        handle: {
          crumb: () => 'Categories',
        },
        children: [
          {
            path: ":categoryId",
            element: <AdminCatalogMasterManagerAddEditCategoryPage title="Master Catalog Manager - Edit Category" />,
            handle: {
              crumb: () => { return `Edit Category` },
            },
          },
          {
            path: "new",
            element: <AdminCatalogMasterManagerAddEditCategoryPage title="Master Catalog Manager - Add Category" />,
            handle: {
              crumb: () => { return `Add Category` },
            },
          },
        ]
      },
      {
        path: "subcategories",
        handle: {
          crumb: () => 'Subcategories',
        },
        children: [
          {
            path: ":subcategoryId",
            element: <AdminCatalogMasterManagerAddEditSubcategoryPage title="Master Catalog Manager - Edit Subcategory" />,
            handle: {
              crumb: () => { return `Edit Subcategory` },
            },
          },
          {
            path: "new",
            element: <AdminCatalogMasterManagerAddEditSubcategoryPage title="Master Catalog Manager - Add Subcategory" />,
            handle: {
              crumb: () => { return `Add Subcategory` },
            },
          },
        ]
      },
      {
        path: "effects",
        handle: {
          crumb: () => 'Effects',
        },
        children: [
          {
            path: ":effectId",
            element: <AdminCatalogMasterManagerAddEditEffectPage title="Master Catalog Manager - Edit Effect" />,
            handle: {
              crumb: () => { return `Edit Effect` },
            },
          },
          {
            path: "new",
            element: <AdminCatalogMasterManagerAddEditEffectPage title="Master Catalog Manager - Add Effect" />,
            handle: {
              crumb: () => { return `Add Effect` },
            },
          },
        ]
      },
      {
        path: "terpenes",
        handle: {
          crumb: () => 'Terpenes',
        },
        children: [
          {
            path: ":terpeneId",
            element: <AdminCatalogMasterManagerAddEditTerpenePage title="Master Catalog Manager - Edit Terpene" />,
            handle: {
              crumb: () => { return `Edit Terpene` },
            },
          },
          {
            path: "new",
            element: <AdminCatalogMasterManagerAddEditTerpenePage title="Master Catalog Manager - Add Terpene" />,
            handle: {
              crumb: () => { return `Add Terpene` },
            },
          },
        ]
      },
      {
        path: "cannabinoids",
        handle: {
          crumb: () => 'Cannabinoids',
        },
        children: [
          {
            path: ":cannabinoidId",
            element: <AdminCatalogMasterManagerAddEditCannabinoidPage title="Master Catalog Manager - Edit Cannabinoid" />,
            handle: {
              crumb: () => { return `Edit Cannabinoid` },
            },
          },
          {
            path: "new",
            element: <AdminCatalogMasterManagerAddEditCannabinoidPage title="Master Catalog Manager - Add Cannabinoid" />,
            handle: {
              crumb: () => { return `Add Cannabinoid` },
            },
          },
        ]
      },
      {
        path: "strain-types",
        handle: {
          crumb: () => 'Strain Types',
        },
        children: [
          {
            path: ":strainTypeId",
            element: <AdminCatalogMasterManagerAddEditStrainTypePage title="Master Catalog Manager - Edit Strain Type" />,
            handle: {
              crumb: () => { return `Edit Strain Type` },
            },
          },
          {
            path: "new",
            element: <AdminCatalogMasterManagerAddEditStrainTypePage title="Master Catalog Manager - Add Strain Type" />,
            handle: {
              crumb: () => { return `Add Strain Type` },
            },
          },
        ]
      },

    ]
  },
  {
    path: "mcc",
    element: <AdminMccPage title="Manage MCC's" />,
    handle: {
      crumb: () => 'MCC',
    },
  },
];

export default AdminRoutes;
